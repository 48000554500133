import { getCookie } from 'cookies-next'
import { ACCESS_TOKEN } from 'libs'
import Link from 'next/link'
import {
  getNotificationCount,
  useNotificationCountStore,
  type MessageEventData,
} from 'notifications'
import { useEffect, useRef } from 'react'
import type { DeepPartial } from 'react-hook-form'
import { ToastAction, toast } from 'ui'
import { firebaseCloudMessaging } from '@/shared/utils/firebase'
import { ClientRoutes } from '@/shared/routes/client.route'

export const usePushNotification = () => {
  const firebaseMessageId = useRef<string>()
  const content = useRef<string>()

  const setNotificationCountResponse = useNotificationCountStore(
    (state) => state.setNotificationCountResponse
  )
  const updateNotificationCount = async () => {
    const accessToken = getCookie(ACCESS_TOKEN)
    if (!accessToken) return

    const notificationCountResponse = await getNotificationCount({
      accessToken,
      appHeader: 'panel',
    })

    // TODO: Is not setting correctly the state after request is made when a notification is opened
    setNotificationCountResponse(notificationCountResponse)
  }

  useEffect(() => {
    async function setToken() {
      try {
        const token = await firebaseCloudMessaging.init()
        console.log({ token })
      } catch (error) {
        console.log(error)
      }
    }

    void setToken()

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener(
        'message',
        async (event: MessageEvent<DeepPartial<MessageEventData>>) => {
          const notificationPayload = event.data.firebaseMessaging?.payload
          const title = notificationPayload?.notification?.title
          const body = notificationPayload?.notification?.body
          const id = notificationPayload?.fcmMessageId

          const titleWithBody = `${title} - ${body}`.toLowerCase()
          const isSameContent = titleWithBody === content.current
          const isSameId = id === firebaseMessageId.current

          if (isSameContent || isSameId) {
            return
          }

          console.log('event for the service worker', event)

          firebaseMessageId.current = id
          content.current = titleWithBody

          document.addEventListener('visibilitychange', () => {
            // TODO: Set notification badge if app is in background
            if (document.visibilityState !== 'visible') {
              console.log("I'm not visible")
            }
          })
          /* "754729476970" */
          const registration = await navigator.serviceWorker.getRegistration()

          if (title) {
            toast({
              title,
              description: body,
              action: notificationPayload.data?.userId ? (
                <ToastAction altText="Detalles" asChild>
                  <Link
                    href={`${ClientRoutes.Users}/${notificationPayload.data.userId}`}
                  >
                    Detalles
                  </Link>
                </ToastAction>
              ) : undefined,
            })
            await Promise.all([
              updateNotificationCount(),
              registration?.showNotification(title, {
                body,
              }),
            ])
          }
          content.current = ''
          firebaseMessageId.current = ''
        }
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
