import type { EntityName } from '../types'

type ClientRoutesKey =
  | Exclude<
      Capitalize<EntityName>,
      | 'LockedCardsHistory'
      | 'LockedDevicesHistory'
      | 'PaymentConfigByUser'
      | 'ServStore'
      | 'SystemConfigByUser'
    >
  | 'Login'
  | 'Dashboard'
  | 'Synchronize'
  | 'StoreLockerPackages'

export const ClientRoutes: Record<ClientRoutesKey, string> = {
  Login: '/login',
  Users: '/users',
  Dashboard: '/dashboard',
  Devices: '/devices',
  PaymentOperations: '/operations',
  PaymentTransactions: '/transactions',
  BalanceTransfs: '/balance-transfers',
  BalanceHistory: '/balance-histories',
  IpInfo: '/ip-info',
  ServCalls: '/calls',
  ServSms: '/sms',
  Cards: '/cards',
  Addresses: '/addresses',
  IdentityDocs: '/identity-docs',
  Beneficiaries: '/beneficiaries',
  Phones: '/phones',
  Emails: '/emails',
  ServRemittances: '/remittances',
  PaymentRefunds: '/payment-refunds',
  PaymentMaxmind: '/payment-maxmind',
  ServTopups: '/topups',
  OperatorsActions: '/operators-actions',
  Notifications: '/notifications',
  Promotions: '/promotions',
  ServiceConfig: '/service-config',
  PaymentConfig: '/payment-rules',
  PaymentGatewayConfig: '/payment-gateway-config',
  BeneficiariesAccounts: '/beneficiaries-accounts',
  RemittanceConfig: '/remittance-config',
  ServShopping: '/service-shopping',
  Synchronize: '/synchronize',
  SystemConfig: '/system-config',
  CardFailedHistory: '/card-failed-history',
  Categories: '/categories',
  Providers: '/providers',
  ServLockerShipments: '/locker-shipments',
  ServLockerShipmentPackages: '/locker-packages',
  StoreLockerPackages: '/store-locker-packages',
  HelpConfig: '/help-config',
  ServFlight: '/flights',
}
